import {
  ArrowBackIosNewOutlined,
  ConfirmationNumber,
  Place,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { handleClose, handleOpen } from "../config/config";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { t } from "i18next";
import toast from "react-hot-toast";
import api from "../API/apiCollection";
import { useSelector, useDispatch } from "react-redux";
import Stripe from "../PaymentGateways/Stripe";
import {
  selectedPromoCodeData,
  setPromoCode,
  setSelectedPromocode,
} from "../redux/Promocode";
import { useTheme } from "@emotion/react";
import Paystack from "../PaymentGateways/Paystack";
import RazorPay from "../PaymentGateways/RazorPay";
import Paypal from "../PaymentGateways/Paypal";
import { DECIMAL_POINT } from "../config/config";
import { cartDetails, orderCartDetailsReset } from "../redux/orderCartDetails";
import { resetState } from "../redux/cart";
import { useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { paymentMode, setPaymentMode } from "../redux/Settings";
import FlutterwavePaymentComponent from "../PaymentGateways/FlutterWave";

//closedrawer
const BookingInfoDrawerNew = ({ setForm, setPromo, setBooking }) => {
  const decimal_point = DECIMAL_POINT();
  const dispatch = useDispatch();
  // for fetching cart details
  const [rows, setRows] = useState([]);
  const [amount, setAmount] = useState();
  const [visitingCharges, setVisitingCharges] = useState();
  const [addressInfo, setAddressInfo] = useState();
  const settings = useSelector((state) => state.Settings)?.settings;
  const currency_symbol = settings?.app_settings?.currency;
  const navigate = useNavigate();
  const selectedPromo = useSelector(selectedPromoCodeData);
  const paymentModeData = useSelector(paymentMode);
  const [Key, setKey] = useState("");

  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const orderNotes = orderDetails.orderNote;

  // we need this in razorpay order place api
  const theme = useTheme();

  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress
  )?.delivery;

  // open and close drawer
  function back() {
    handleClose(setBooking);
    handleOpen(setForm);
  }

  //table row styling
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  //table cell styling
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  let promocode = useSelector((state) => state.Promocode);
  const coupanCodes = promocode.promocode;
  let selectedPromoCode = promocode.selectedPromoCode;

  //for fetching cart information when drawer open
  let AllCartData = useSelector((state) => state.cart);

  useEffect(() => {
    const stripeKey = settings.payment_gateways_settings.stripe_publishable_key;
    const clientSecret = settings.payment_gateways_settings.stripe_secret_key;
    localStorage.setItem("stripeKey", stripeKey);
    localStorage.setItem("clientSecret", clientSecret);
    setKey(stripeKey);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (delivery_type === "shop") {
      if (AllCartData?.isFrom === "reorder") {
        setAmount(Number(AllCartData?.reOrder.sub_total));
      } else {
        setAmount(Number(AllCartData?.base_cart?.sub_total));
      }
    } else {
      if (AllCartData?.isFrom === "reorder") {
        setAmount(
          Number(AllCartData?.reOrder?.sub_total) +
            Number(AllCartData?.reOrder?.visiting_charges)
        );
      } else {
        setAmount(
          Number(AllCartData?.base_cart?.sub_total) +
            Number(AllCartData?.base_cart?.visiting_charges)
        );
      }
    }

    if (AllCartData?.isFrom === "reorder") {
      setRows(AllCartData?.reOrder?.data);
      setVisitingCharges(AllCartData?.reOrder?.visiting_charges);
    } else {
      setRows(AllCartData?.base_cart?.data);
      setVisitingCharges(Number(AllCartData?.base_cart?.visiting_charges));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // to open promocode drawer
  function openPromo() {
    handleClose(setBooking);
    handleOpen(setPromo);
  }

  const removePromo = () => {
    dispatch(setPromoCode([]));

    if (selectedPromo) {
      dispatch(setSelectedPromocode(null));
      toast.success("Coupon Remove Successfully");
    } else toast.error("No Coupon Selected");
  };

  //delivery method and other stuff

  const selectedDate = orderDetails && orderDetails.date;
  const selectedTime = orderDetails && orderDetails.slot;

  useEffect(() => {
    if (deliveryAddress) {
      setAddressInfo(deliveryAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ==========payment integrations==============
  //when user place order
  const placeOrder = async () => {
    let address = "";
    if (delivery_type === "Home") {
      address = deliveryAddress;
    }

    await api
      .placeOrder({
        method: paymentModeData,
        date: selectedDate,
        time: selectedTime,
        addressId: delivery_type === "Home" ? address?.id : "",
        order_note: orderNotes,
        promo_code_id: selectedPromoCode?.id,
      })
      .then((response) => response)
      .then(async (result) => {
        if (paymentModeData === "cod") {
          toast.success(t("Service booked successfully"));
        } else {
          toast.success(result.message);
        }
        if (result.error === false) {
          setTimeout(async () => {
            await api
              .add_transactions({
                orderID: result.data.order_id,
                status: "success",
              })
              .then((response) => response)
              .then((res) => {
                dispatch(orderCartDetailsReset());
                dispatch(resetState());
                navigate("/profile/booking/services/" + result.data.order_id);
                dispatch(cartDetails({ orderNote: "" }));
              });
          }, 2000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const key = localStorage.getItem("stripeKey");
  const stripekey = key;
  const stripePromise = loadStripe(stripekey);

  const currencyCountryCode =
    settings?.payment_gateways_settings?.stripe_currency;
  const paymentsettings = settings?.payment_gateways_settings;

  let stripe_secret = paymentsettings?.stripe_secret_key;
  const stripeX = require("stripe")(stripe_secret);

  const [clientKey, setClientKey] = useState("");

  useEffect(() => {
    const createPaymentIntent = async () => {
      if (amount) {
        try {
          const paymentIntent = await stripeX.paymentIntents.create({
            amount: Math.round(amount * 100), // Amount in cents
            currency: currencyCountryCode,
            description: "payment",

            // Add other parameters as needed
          });
          setClientKey(paymentIntent.client_secret);
          // Handle the created payment intent here
        } catch (error) {
          console.error("Error creating payment intent:", error);
          // Handle any errors here
        }
      }
    };

    createPaymentIntent();
    // eslint-disable-next-line
  }, [amount, currencyCountryCode]);

  const options = {
    clientSecret: clientKey,
    // Fully customizable with appearance API.
    appearance: {
      theme: "stripe",
    },
  };

  useEffect(() => {}, [clientKey]);

  const [paymentMethods, setPaymentMethods] = useState([]);

  const [multiplePaymentMethodsEnabled, setMultiplePaymentMethodsEnabled] =
    useState(false);

  const [singlePaymentMethod, setSinglePaymentMethod] = useState(null);

  const payment_gateway_setting = paymentsettings?.payment_gateway_setting;

  const cod_setting = paymentsettings?.cod_setting;

  const StripeStatus =
    payment_gateway_setting === 1 ? paymentsettings.stripe_status : "disable";

  const RazorStatus =
    payment_gateway_setting === 1
      ? paymentsettings.razorpayApiStatus
      : "disable";

  const PaypalStatus =
    payment_gateway_setting === 1 ? paymentsettings.paypal_status : "disable";

  const PayStackStatus =
    payment_gateway_setting === 1 ? paymentsettings.paystack_status : "disable";

  const flutterWaveStatus =
    payment_gateway_setting === 1
      ? paymentsettings?.flutterwave_status
      : "disable";

  const is_pay_later_allowed =
    AllCartData?.base_cart?.is_pay_later_allowed == 1 && cod_setting == 1;

  const is_online_payment_allowed =
    AllCartData?.base_cart?.is_online_payment_allowed == 1 &&
    payment_gateway_setting == 1;

  const reOrder_is_pay_later_allowed =
    AllCartData?.reOrder?.is_pay_later_allowed == 1 && cod_setting == 1;

  const reOrder_is_online_payment_allowed =
    AllCartData?.reOrder?.is_online_payment_allowed == 1 &&
    payment_gateway_setting == 1;

  // If only COD is enabled then we will show direct Book Services on button and place COD order
  // If only Single payment methods is enabled then will show direct Make Payment on button and will make payment through payment gateway
  // If only Payment method enabled and more than one payment method is enabled then we will give an options to the user to select their payment method
  // If there is COD enabled and Single payment method is enabled then we will show option with Pay now and Pay later option
  // If there is COD enabled and multiple payment methods are enabled then we will show options like [Pay later, Pay using stripe, pay using paypal]

  const isMoreThanOnePaymentGatewayEnabled = () => {
    let paymentGatewayCount = 0;

    if (StripeStatus === "enable") paymentGatewayCount++;
    if (RazorStatus === "enable") paymentGatewayCount++;
    if (PaypalStatus === "enable") paymentGatewayCount++;
    if (PayStackStatus === "enable") paymentGatewayCount++;
    if (flutterWaveStatus === "enable") paymentGatewayCount++;
    return paymentGatewayCount > 1;
  };

  useEffect(() => {
    setMultiplePaymentMethodsEnabled(isMoreThanOnePaymentGatewayEnabled());
    // eslint-disable-next-line
  }, [
    StripeStatus,
    RazorStatus,
    PaypalStatus,
    PayStackStatus,
    flutterWaveStatus,
  ]);

  useEffect(() => {
    const methods = getEnabledPaymentMethods();
    setPaymentMethods(methods);
    setMultiplePaymentMethodsEnabled(isMoreThanOnePaymentGatewayEnabled());

    // Set the single payment method if there's only one
    if (methods.length === 1) {
      setSinglePaymentMethod(methods[0].paymentType);
      setActivePaymentMethod(methods[0].paymentType);
    } else {
      setSinglePaymentMethod(null);
    }
    // eslint-disable-next-line
  }, [paymentsettings]);

  useEffect(() => {}, [multiplePaymentMethodsEnabled]);

  const getSingleEnabledPaymentMethod = () => {
    const enabledMethods = [
      { status: StripeStatus, name: "stripe" },
      { status: RazorStatus, name: "razorpay" },
      { status: PayStackStatus, name: "paystack" },
      { status: PaypalStatus, name: "paypal" },
      { status: flutterWaveStatus, name: "flutterwave" },
    ].filter((method) => method.status === "enable");

    return enabledMethods.length === 1 ? enabledMethods[0].name : "";
  };

  const getEnabledPaymentMethods = () => {
    const methods = [
      {
        title: "Pay Now",
        isEnabled:
          AllCartData?.isFrom === "reorder"
            ? reOrder_is_online_payment_allowed
            : is_online_payment_allowed
            ? !isMoreThanOnePaymentGatewayEnabled()
            : isMoreThanOnePaymentGatewayEnabled(),
        paymentType: getSingleEnabledPaymentMethod(),
      },
      {
        title: "Paypal",
        isEnabled:
          PaypalStatus === "enable" && isMoreThanOnePaymentGatewayEnabled(),
        paymentType: "paypal",
      },
      {
        title: "Razorpay",
        isEnabled:
          RazorStatus === "enable" && isMoreThanOnePaymentGatewayEnabled(),
        paymentType: "razorpay",
      },
      {
        title: "Paystack",
        isEnabled:
          PayStackStatus === "enable" && isMoreThanOnePaymentGatewayEnabled(),
        paymentType: "paystack",
      },
      {
        title: "Stripe",
        isEnabled:
          StripeStatus === "enable" && isMoreThanOnePaymentGatewayEnabled(),
        paymentType: "stripe",
      },
      {
        title: "Flutterwave",
        isEnabled:
          flutterWaveStatus === "enable" &&
          isMoreThanOnePaymentGatewayEnabled(),
        paymentType: "flutterwave",
      },
      {
        title: "Pay On Service",
        isEnabled:
          AllCartData?.isFrom === "reorder"
            ? reOrder_is_pay_later_allowed
            : is_pay_later_allowed,
        paymentType: "cod",
      },
    ];

    return methods.filter((method) => method.isEnabled);
  };

  const [activePaymentMethod, setActivePaymentMethod] = useState(null);

  const handlePaymentMethodChange = (e, paymentType) => {
    e.preventDefault();
    setActivePaymentMethod(paymentType);
  };

  useEffect(() => {
    setPaymentMethods((methods) => {
      return methods;
    });
  }, [paymentMethods]);

  useEffect(() => {}, [promocode]);

  return (
    <Box display={"block"} width={"80"}>
      <Box mt={1} display={"flex"} alignItems={"center"}>
        <IconButton onClick={back}>
          <ArrowBackIosNewOutlined />
        </IconButton>
        <h3>{t("booking_info")}</h3>
      </Box>
      <Divider />

      <Box p={2}>
        {singlePaymentMethod ? (
          // Render the single payment method directly
          <Typography variant="h6" gutterBottom>
            {/* Payment Method: {singlePaymentMethod} */}
          </Typography>
        ) : (
          // Render radio buttons for multiple payment methods
          <FormControl
            component="fieldset"
            sx={{
              margin: "0 0 20px 0",
            }}
          >
            {paymentMethods &&
              paymentMethods.map((method) => (
                <React.Fragment key={method.paymentType}>
                  <FormControlLabel
                    value={method.paymentType}
                    control={
                      <Radio
                        checked={activePaymentMethod === method.paymentType}
                        onChange={(e) =>
                          handlePaymentMethodChange(e, method.paymentType)
                        }
                      />
                    }
                    label={`Pay using ${method.title}`}
                  />
                </React.Fragment>
              ))}
          </FormControl>
        )}

        <Box border={"1px solid gray"} borderRadius={2}>
          <Box display={"flex"} p={1} gap={2} alignItems={"center"}>
            <AccessTimeIcon
              className="icon"
              sx={{ fontWeight: "thin" }}
              fontSize="large"
            />
            <Box>
              <Typography variant="caption" fontSize={14}>
                {t("booking_date")}
              </Typography>
              <Typography fontWeight={"bold"}>
                {dayjs(selectedDate).format("DD/MM/YYYY")}, {selectedTime}
              </Typography>
            </Box>
          </Box>
          {delivery_type === "Home" ? (
            <>
              <Divider />
              <Box display={"flex"} p={1} gap={2} alignItems={"center"}>
                <Place className="icon" fontSize="large" />
                <Box>
                  <Typography color={"gray"} fontSize={14}>
                    {t("your_booking_address")}
                  </Typography>
                  <Typography fontWeight={"bold"}>
                    {addressInfo ? addressInfo.address : ""}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
        </Box>

        <Box sx={{ my: 5 }}>
          <Button
            variant="contained"
            fullWidth
            className="promocode-btn"
            mt={2}
            border={"1px solid"}
            sx={{
              textTransform: "none",
              borderRadius: "10px",
              backgroundColor: theme.palette.background.buttonColor,
            }}
            p={2}
            onClick={openPromo}
            borderRadius={"8px"}
          >
            {t("aplly_promo")}
          </Button>
        </Box>

        {coupanCodes?.length > 0 ? (
          <Box
            my={5}
            px={2}
            py={2}
            sx={{ backgroundColor: "#343F53", borderRadius: "8px" }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} gap={2}>
                  <ConfirmationNumber sx={{ color: "white" }} />
                  <Typography
                    color={"white"}
                    fontWeight={"400"}
                    variant="subtitle2"
                  >
                    {selectedPromoCode ? selectedPromoCode.promo_code : ""}
                  </Typography>
                </Box>
                <Typography variant="body1" color={"white"}>
                  {selectedPromoCode ? (
                    <Typography
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={0.4}
                      variant="subtitle2"
                      color={"white"}
                    >
                      <Box>{selectedPromoCode?.discount}</Box>
                      <Box>
                        {selectedPromoCode?.discount_type === "percentage"
                          ? "%"
                          : currency_symbol}
                      </Box>
                    </Typography>
                  ) : (
                    "Coupons"
                  )}
                </Typography>
                <Button
                  variant="contained"
                  mt={2}
                  border={"1px solid"}
                  sx={{
                    textTransform: "none",
                    borderRadius: "10px",
                    backgroundColor: theme.palette.background.buttonColor,
                  }}
                  p={2}
                  onClick={removePromo}
                  borderRadius={"8px"}
                >
                  <DeleteIcon />
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          ""
        )}

        {/* Table that contain information about our cart  */}
        <TableContainer
          className="add-address-btn"
          component={Paper}
          sx={{ mt: 5 }}
        >
          <Table className="booking-info-table" aria-label="customized table">
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.servic_details.title}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.qty}</StyledTableCell>
                  <StyledTableCell align="right">
                    {currency_symbol} {row.servic_details.price_with_tax}
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {t("sub_total")}
                </StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
                <StyledTableCell align="right">
                  {currency_symbol}{" "}
                  {AllCartData?.isFrom === "reorder"
                    ? AllCartData?.reOrder?.sub_total
                    : AllCartData?.base_cart?.sub_total}
                </StyledTableCell>
              </StyledTableRow>
              {delivery_type === "Home" ? (
                <>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      {t("visiting_charges")}
                    </StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right">
                      +{currency_symbol} {visitingCharges}
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ) : (
                ""
              )}
              {coupanCodes && coupanCodes[0]?.final_discount > "0" ? (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    {t("promocode_discount")}
                  </StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                  <StyledTableCell align="right">
                    - {currency_symbol}
                    {coupanCodes[0]?.final_discount}
                  </StyledTableCell>
                </StyledTableRow>
              ) : null}
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  <Typography variant="body1" fontWeight={"bold"}>
                    {t("total_amount")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
                <StyledTableCell align="right">
                  <Typography
                    variant="body1"
                    fontWeight={"bold"}
                    color={theme?.palette?.primary?.main}
                  >
                    {currency_symbol}
                    {coupanCodes
                      ? amount -
                        (coupanCodes && coupanCodes?.length > 0
                          ? coupanCodes[0]?.final_discount
                          : 0)
                      : amount}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={2} px={2}>
        {activePaymentMethod === "stripe" && Key && (
          <>
            {clientKey && (
              <Elements stripe={stripePromise} options={options}>
                <Stripe
                  clientKey={clientKey}
                  amount={
                    coupanCodes
                      ? parseFloat(
                          amount - coupanCodes[0]?.final_discount
                        ).toFixed(decimal_point)
                      : parseFloat(amount)
                  }
                  promoCode={coupanCodes?.length > 0 ? coupanCodes : []}
                />
              </Elements>
            )}
          </>
        )}

        {activePaymentMethod === "paystack" && (
          <Paystack
            amount={
              coupanCodes
                ? parseFloat(amount - coupanCodes[0]?.final_discount).toFixed(
                    decimal_point
                  )
                : parseFloat(amount)
            }
            promoCode={coupanCodes?.length > 0 ? coupanCodes : []}
          />
        )}

        {activePaymentMethod === "paypal" && (
          <Paypal
            amount={
              coupanCodes
                ? parseFloat(amount - coupanCodes[0]?.final_discount).toFixed(
                    decimal_point
                  )
                : parseFloat(amount)
            }
            promoCode={coupanCodes?.length > 0 ? coupanCodes : []}
          />
        )}

        {activePaymentMethod === "razorpay" && (
          <RazorPay
            amount={
              coupanCodes
                ? parseFloat(amount - coupanCodes[0]?.final_discount).toFixed(
                    decimal_point
                  )
                : parseFloat(amount)
            }
            promoCode={coupanCodes?.length > 0 ? coupanCodes : []}
          />
        )}

        {activePaymentMethod === "flutterwave" && (
          <FlutterwavePaymentComponent
            amount={
              coupanCodes
                ? parseFloat(amount - coupanCodes[0]?.final_discount).toFixed(
                    decimal_point
                  )
                : parseFloat(amount)
            }
          />
        )}

        {activePaymentMethod === "cod" && (
          <Box mt={2}>
            <Button
              variant="outlined"
              fullWidth
              className="place-order-btn"
              onClick={() => {
                dispatch(setPaymentMode("cod"));
                placeOrder();
              }}
            >
              {t("place_order")}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BookingInfoDrawerNew;
